<template lang="pug">
.content
  section.container-fluid.m-t-20

    a-breadcrumb(separator=">")
      a-breadcrumb-item
        router-link(to="/") 控制台
      a-breadcrumb-item 设置
    
    .m-t-15.m-b-20
      h1.section-title.inline 设置
    
    //- a-card.m-b-25()
      h3(slot="title") setting.basic
      a-button(size="small" type="primary" :loading="loading" @click="handleSubmit('form')" slot="extra") save

      //- Form(:style="{ padding: '16px' }" label-position="left" :label-width="180" ref="form" :model="detail" :rules="rules")
      //-   FormItem(label="icon" prop="icon")
      //-     div(style="display: block; width: 80px; height: 80px; overflow: hidden; border-radius:7px;")
      //-       vcUploader(:style="{ top: '50%', transform: 'translate3d(0, -50%, 0)' }" :data="detail.appicon" :options="options" @success="onSuccess")
              
      //-   //- FormItem(label="categories")
      //-   //-   Select(v-model="data_categories" clearable)
      //-   //-     Option(v-for="item in categories" :key="item.id" :value="item.id")
      //-   //-       span(v-for="i in item.level" :key="i") &mdash;
      //-   //-       | item.name
        
      //-   FormItem(label="app_key" prop="app_key") {{ detail.app_key }}

      //-   FormItem(label="app_secret" prop="app_secret") {{ detail.app_secret | hidden(isHidden) }}
      //-     span.pointer.m-l-5(@click="toggleHidden")
      //-       Icon(type="md-eye" size="20" v-if="isHidden")
      //-       Icon(type="md-eye-off" size="20" v-else)

      //-     Poptip(confirm title="刷新后，所有授权的应用都需要使用新的密钥进行更新。确定刷新吗？（该操作不可逆）" @on-ok="chagneSecret")
      //-       span.pointer.m-l-10 刷新密钥
      //-         Icon(type="md-refresh" size="18")

      //-   FormItem(label="name" prop="name")
      //-     Input(v-model="detail.name" placeholder="placeholder.application.name")
          
      //-   FormItem(label="description" prop="description")
      //-     Input(v-model="detail.description" type="textarea" :autosize="{ minRows: 3, maxRows: 5 }" placeholder="placeholder.application.description")
      
      //- code {{ detail }}
</template>

<script>
import { mapState } from 'vuex'

//- import vcUploader from '@/components/vcUploader/'
export default {
  name: 'Basic',
  components: {
    //- vcUploader
  },
  data() {
    return {
      isHidden: true,
      loading: false,
      formDestroy: {
        name: ''
      },
      detail: {},
      rules: {
        name: [
          { required: true, message: 'Please fill in the application name', trigger: 'blur' }
        ]
      },
      options: {
        type: 'upload',
        multiple: false,
        app: 'admin',
        module: 'application',
        key: 'appicon',
        tip: '尺寸大小建议 240 × 240 像素'
      }
    }
  },
  filters: {
    hidden(val, isHidden) {
      if (!val) return '';
      let str = [];
      for (let i = 0; i < 32; ++i) {
        str[i] = i > 5 && isHidden ?  '*' : val[i];
      }
      return str.join('');
    }
  },
  computed: {
    ...mapState({
      config: state => state.system.config
    }),
  },
  methods: {
    toggleHidden() {
      this.isHidden = !this.isHidden;
    },
    /*********************************************************
     * @description 加载数据
     *********************************************************/
    getDetail() {
      this.$http.get(`application/detail`).then(res => {
        this.detail = res;
      }).catch(err => {
        console.log(err);
      });
    },

    /*********************************************************
     * @description 更改密钥
     *********************************************************/
    chagneSecret() {
      this.$http.put(`application/secret`).then(res => {
        this.detail.app_secret = res;
      }).catch(err => {
        console.log(err);
      });
    },

    /*********************************************************
     * @description 删除应用
     *********************************************************/
    deleteConfirm() {
      const router = this.$router;
      this.$Modal.confirm({
        loading: true,
        title: `请输入要删除的应用名称: ${this.detail.name}`,
        render: h => {
          const Input = h('Input', {
            props: {
              value: this.formDestroy.name,
              placeholder: '请输入要删除应用名称'
            },
            on: {
              input: val => {
                this.formDestroy.name = val;
              }
            }
          });

          const FormItem = h('FormItem', {
            props: {
              prop: 'name'
            }
          }, [ Input ]);

          const Form = h('Form', {
            class: 'm-t-15',
            ref: 'formModal',
            props: {
              model: this.formDestroy,
              rules: {
                name: [
                  { required: true, message: '请输入正确应用名称', trigger: 'blur' }
                ],
              },
            }
          }, [ FormItem ]);

          return Form;
        },
        onOk() {
          this.$refs['formModal'].validate(async valid => {
            if (valid) {
              await this.$http.delete(`application`);
              this.$refs['formModal'].resetFields();
              this.$Modal.remove();
              this.$message.success('应用已删除');
              router.replace({ name: `ApplicationManager` });
            } else {
              this.$nextTick(() => {
                this.$data.buttonLoading = false;
              });
            }
          });
        }
      });
    },


    /*********************************************************
     * @description 上传组件回调 删除/上传成功
     *********************************************************/
    // 删除/上传成功
    onSuccess(key, res) {
      this.detail[key] = res;
    },

    /*********************************************************
     * @description 表单提交
     *********************************************************/
    handleSubmit(name) {
      this.$refs[name].validate(valid => {
        if (!valid) return this.$message.error('提交失败!');

        this.loading = true;

        this.$http.put('application', this.detail).then(() => {
          this.loading = false;
          this.$message.success('提交成功!');
        }).catch(err => {
          this.loading = false;
          this.$message.error(err.error);
        });
      });
    }
  },
  created() {},
  mounted() {
    //- this.getDetail();
  },
}
</script>

<style lang="less" scoped>
.delete-btn {
  position: absolute; right: 20px; top: 50%;
  transform: translate3d(0, -50%, 0);
}

// 翻页组件右对齐时，sizer 向右不留空白
.ivu-page {
  &.right {
    .ivu-page-options-sizer { margin-right: 0; }
  }
}
// 必填标注往左移动
.ivu-form-item-required {
  .ivu-form-item-label::before {
    margin-left: -12px;
  }
}
</style>